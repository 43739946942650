import React, {
  memo,
  forwardRef,
  useState,
  useCallback,
  useEffect,
	useMemo
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {Trans, useTranslation} from 'react-i18next';
import cx from 'classnames';
import {useEnsuredForwardRef,getLocalStorage, setLocalStorage} from 'assets/js/helper';
import { usePrenatalStatus, useHideMeology,useMeologyHistory } from 'assets/js/hooks';
import {PrenatalStep} from 'components/results/Prenatal';
import RecommendationCard from 'components/results/Recommendations/RecommendationCard';
import { Toggle } from 'components/results/Kids';
import LearnMoreModal from 'components/results/Recommendations/LearnMoreModal';
import { pushInteractionToDataLayer } from 'components/analytics/analyticsActions';

const Banner = (
	{
		currentPills,
		pillsCount,
		selectedAnswers,
		currentPillsTotalPrice,
		onClick,
		trimester
	},
	topRef
) => {
  const dispatch = useDispatch();
	const ensuredTopRef = useEnsuredForwardRef(topRef);
	const { t } = useTranslation();
	const [showLearnMoreModal, setShowLearnMoreModal] = useState(false);
  const { isPreviewPostnatal, phaseStatus, prenatalStep } = usePrenatalStatus();
  const { isShowToggle } = useHideMeology();
  const { isUserLoggedin } = useSelector(state => state.user);
	const [showBanner, setShowBaner] = useState(true);
	const {isPrenatalToRegular} = useMeologyHistory()

	const handleClose = () => {
    setShowBaner(false);
    setLocalStorage('closeBannerStatus', {
      isClose: true,
      loginStatus: isUserLoggedin,
    });
  };

	const submit = useCallback(() => {
    // eslint-disable-next-line no-unused-expressions
    onClick?.('PREGNANT');
  }, [onClick]);

  const toRegularWarning = useMemo(() => {
    if (!isPrenatalToRegular) return <></>;
    return (
      <div className='prenatal-welcome warning'>
        <div className="row content"  dangerouslySetInnerHTML={{
          __html: t('reco.prenatal_to_regular_warning')
        }} />
      </div>
    );
  }, [isPrenatalToRegular, t]);

  useEffect(()=> {
    const { isClose, loginStatus } = getLocalStorage('closeBannerStatus') || {};

    if (loginStatus !== isUserLoggedin) {
      setShowBaner(true);
    } else {
      if (isClose) {
        setShowBaner(false);
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

	return (
		<>
		{toRegularWarning}
		{!isPrenatalToRegular && showBanner && (
        <div className="prenatal-welcome">
          <div className="row content">
            <div className="col-11">
              <Trans>{t('ui.recommendation.preparing.banner')}</Trans>
              <button className='learn-more'
                onClick={() => {
                  setShowLearnMoreModal(true);

                  // Google Analysis
                  dispatch(
                    pushInteractionToDataLayer({
                      eventCategory: 'Prenatal',
                      eventAction: 'Learn More_banner',
                      eventLabel: 'Reco Page',
                    })
                  );
                }}
              >
                {t('meology_21.promo.more')}
              </button>
              <LearnMoreModal
                show={showLearnMoreModal}
                closeModal={() => {
                  setShowLearnMoreModal(false);
                }}
              />
            </div>
            <div className="col-1">
              <button className='close' aria-label='Close' onClick={handleClose}>X</button>
            </div>
          </div>
        </div>
      )}
      <div
        className={cx('prenatal-banner', {
					'is-preparing': prenatalStep === 'TRYING',
          'is-pregnant': prenatalStep === 'PREGNANT',
          'is-postnatal': prenatalStep === 'POSTPARTUM',
        })}
        ref={ensuredTopRef}
      >
				<div className="prenatal-banner-top">
					{isShowToggle && <Toggle meologyType="PRENATAL"/>}
				</div>
				<h2 className='prenatal-title'>
					{t('ui.recommendation.prenatal.title')}
				</h2>
				<PrenatalStep/>
				{!isPreviewPostnatal && (
          <div className="prenatal-banner-bottom">
            <h3 className="prenatal-banner-title">
              {t(`ui.recommendation.prenatal.title.${prenatalStep}`)}
            </h3>
			{!phaseStatus.next && prenatalStep === 'PREGNANT' &&
              trimester && (
                <h4 className="prenatal-banner-label">
                  {t(`ui.recommendation.prenatal.PREGNANT.label${trimester}`)}
                </h4>
            )}
            <p className="prenatal-banner-subtitle">
              {t(`ui.recommendation.prenatal.subtitle.${prenatalStep}`)}
            </p>
            <RecommendationCard
              currentPills={currentPills}
              pillsCount={pillsCount}
              selectedAnswers={selectedAnswers}
              meologyType="PRENATAL"
            />
            <div className="font-bold font-gilmer-regular">
              {`${t('ui.recommendation.monthly_supply')} | $${(
                currentPillsTotalPrice / 30
              ).toFixed(2)} ${t('ui.recommendation.daily_cost')}`}
            </div>
          </div>
        )}
				{isPreviewPostnatal && (
          <div className="preview-postnatal-head">
            <p
							className="preview-postnatal-head-text"
              dangerouslySetInnerHTML={{
                __html: t('ui.recommendation.transition_page.text3'),
              }}
            ></p>
            <button className="preview-postnatal-head-button" type="button" onClick={submit}>
              {t('ui.recommendation.prenatal.cta7')}
            </button>
          </div>
        )}
			</div>
		</>
	);
};

export default memo(forwardRef(Banner));
