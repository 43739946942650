import { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useParams } from 'react-router-dom';
import _ from 'lodash';
import {
  isEmpty,
  getLocalStorage,
  getHybrisHost,
  getHybrisUrlParams,
  getUrlVars,
  setLocalStorage,
  PRENATAL_TYPE,
  REGULAR_TYPE,
  KIDS_TYPE,
  getPrenatalPhaseStatus,
  isRSWValidTime,
  isRSW,
} from 'assets/js/helper';
import {
  KIDS_SACHET_SKU_US,
  KIDS_SACHET_SKU_CA,
  BOTTLED_PILLS,
  PRENATAL_TRYING_SACHET_SKU_US,
  PRENATAL_PREGNANT_SACHET_SKU_US,
  PRENATAL_POSTPARTUM_SACHET_SKU_US,
} from 'components/results/Recommendations/RecommendationsConstants';
import Cookies from "js-cookie";

export const isBottledPill = (sku) => BOTTLED_PILLS.includes(String(sku));
export const useSkus = (country, map) =>
  useMemo(() => map[country], [country, map]);

export const useKidsSachetSku = country =>
  useSkus(country, {
    US: KIDS_SACHET_SKU_US,
    CA: KIDS_SACHET_SKU_CA,
  });

export const usePrenatalSachetSku = country => {
  const { prenatalStep } = useSelector(state => state.recommendationsReducer);

  const map = useMemo(() => ({
    US: {
      TRYING: PRENATAL_TRYING_SACHET_SKU_US,
      PREGNANT: PRENATAL_PREGNANT_SACHET_SKU_US,
      POSTPARTUM: PRENATAL_POSTPARTUM_SACHET_SKU_US,
    },
  }), [])

  return useMemo(() => map?.[country]?.[prenatalStep], [
    country,
    map,
    prenatalStep,
  ]);
};

const getPriceByPriceTier = (priceTier, productInfo) => {
  if (isEmpty(productInfo?.prices)) return 0;
  const priceValue = _.get(
    productInfo.prices.find(item => item.priceTier === priceTier),
    'value',
    0
  );
  return priceValue.toFixed(2);
};
const getFixedPriceByPriceTier = ({priceTier, productInfo, productSKU}) => {
  if (isEmpty(productInfo?.fixedProductPrice) || !productSKU) return 0;
  return _.get(
    productInfo.fixedProductPrice.find(
      item => item.priceTier === priceTier && item.productCode === productSKU
    ),
    'price',
    0
  );
};
export const useProductPrice = productInfo => {
  const { priceTier } = useSelector(state => state.user);
  return getPriceByPriceTier(priceTier, productInfo);
};

export const usePerDayPrice = (product, type, country) => {
  let { sku, quantity } = product || {};
  const priceValue = useProductPrice(product);
  if (type === 'addon' || isBottledPill(sku)) {
    return (priceValue / 30).toFixed(2);
  } else {
    return (priceValue * quantity).toFixed(2);
  }
};
export const useFixedProductPrice = (productInfo, country) => {
  const { priceTier } = useSelector(state => state.user);
  const productSKU = usePrenatalSachetSku(country)
  return getFixedPriceByPriceTier({priceTier, productInfo, productSKU});
};

export const usePrice = (product, type) => {
  const meologyType = getLocalStorage('meologyType');
  let days = 30;
  if (meologyType?.toUpperCase() === 'KIDS') {
    days = 25;
  }
  const priceValue = useProductPrice(product);
  if (type === 'addon' || isBottledPill(product.sku)) {
    return (priceValue * product.quantity).toFixed(2);
  } else {
    return (priceValue * product.quantity * days).toFixed(2);
  }
};

export const useTotalPrice = (data, priceTier, type, country) => {
  const meologyType = getLocalStorage('meologyType');
  let days = 30;
  if (meologyType?.toUpperCase() === 'KIDS') {
    days = 25;
  }
  return useMemo(() => {
    if (isEmpty(data)) return 0;
    return data
      .filter(item => item.selected)
      .reduce((accumulator, current) => {
        const price = getPriceByPriceTier(priceTier, current);
        if (type === 'addon' || isBottledPill(current.sku)) {
          return accumulator + price * current.quantity;
        } else {
          return accumulator + price * current.quantity * days;
        }
      }, 0)
      .toFixed(2);
  }, [data, priceTier, type, days]);
};

export const useFixedProductTotalPrice = (data, priceTier, country) => {
  const productSKU = usePrenatalSachetSku(country)
  return useMemo(() => {
    if (isEmpty(data)) return 0;
    return data
      .filter(item => item.selected)
      .reduce((accumulator, productInfo) => {
        const price = getFixedPriceByPriceTier({priceTier, productInfo, productSKU});
        return accumulator + Number(price);
      }, 0)
      .toFixed(2);
  }, [data, priceTier, productSKU]);
}

export const useDailyTablets = (data, country) => {
  return useMemo(() => {
    if (isEmpty(data)) return 0;
    return data
      .filter(item => item.selected)
      .reduce((accumulator, current) => accumulator + current.quantity, 0);
  }, [data]);
};

export const useTotalItems = data => {
  return useMemo(() => {
    if (isEmpty(data)) return 0;
    return data.filter(item => item.selected).length;
  }, [data]);
};

export const useProductsInfo = (data, key) =>
  useMemo(() => _.get(data, ['recommendations', key], []), [data, key]);

export const useEdited = initData => {
  const data = useMemo(() => initData.filter(item => !isEmpty(item)), [
    initData,
  ]);
  const [edited, setEdited] = useState(_.cloneDeep(data));

  useEffect(() => {
    if (!isEmpty(data)) {
      setEdited(data);
    } else {
      setEdited([]);
    }
  }, [data]);

  const change = useCallback(
    (index, event, option) => {
      let newPills = _.cloneDeep(edited);
      if (event) {
        newPills[index].selected = event.target.checked;
      }
      if (option) {
        newPills[index] = { ...newPills[index], ...option };
      }
      setEdited(newPills);
    },
    [edited]
  );

  const reset = useCallback(() => {
    setEdited(data);
  }, [data]);

  return [edited, change, reset];
};

export const useSelect = (isPrenatal, options, defaultIndex = 0) => {
  const initCode = useMemo(() => _.get(options[defaultIndex], 'code', ''), [defaultIndex, options]);
  const defaultName = useMemo(() => _.get(options[defaultIndex], 'name', ''), [defaultIndex, options]);
  const [code, setCode] = useState(isPrenatal ? '' : initCode);
  const [name, setName] = useState(isPrenatal ? '' : defaultName);

  useUpdateEffect(() => {
    if (!isEmpty(initCode) && !isPrenatal) {
      setCode(initCode);
      setName(defaultName);
    }
  }, [defaultName, initCode, isPrenatal]);

  const change = useCallback(({ code, name }) => {
    setCode(code);
    setName(name);
  }, []);

  return [code, name, change];
};

export const useAddToCart = () => {
  const { addToCartResult } =
    useSelector(state => state.recommendationsReducer) || {};
  const { redirectUrl } = addToCartResult || {};
  const [isAddToCart, setIsAddToCart] = useState(false);
  useEffect(() => {
    if (isEmpty(addToCartResult)) return;
    if (!isEmpty(redirectUrl)) return;
    setIsAddToCart(false);
  }, [addToCartResult, redirectUrl]);

  return { isAddToCart, setIsAddToCart, addToCartResult };
};

export const useIsSku = (skus, sku) =>
  useMemo(() => skus.includes(String(sku)), [skus, sku]);

export const useMeologyHistory = () => {
  const { history } = useSelector(state => state.QuestionReducer);
  const { isUserLoggedin } = useSelector(state => state.user);
  const { kids_info, prenatal_reproductive_status } = history?.questions || {};
  const { view, type, mode } = getUrlVars();
  const isView = view === 'true';
  const { isImpersonation, isSponsor } = getImpersonation();

  const {
    meology_type,
    meology_kids_autoship,
    email,
    loyalty_autoship_info,
    meology_autoship_info,
    hasMeologyPrenatalOrderEntry,
    hasRegularMeologyOrderEntry,
    hasPurchasedPrenatalConceptionPack
  } = history || {};

  const autoshipKidsId = meology_kids_autoship?.map(({ kidId }) => kidId);

  const isPrenatalToRegular = prenatal_reproductive_status === 'REGULAR'

  const newKidsInfo = kids_info?.filter(
    ({ id }) => !autoshipKidsId?.includes(id)
  );

  const newKidsNumber = newKidsInfo?.length ?? 0;

  const autoshipInfos = useMemo(
    () =>
      isEmpty(meology_autoship_info)
        ? loyalty_autoship_info
        : [meology_autoship_info],
    [loyalty_autoship_info, meology_autoship_info]
  );

  const isMeologyKids = useMemo(
    () => type === 'kids' || meology_type === 'KIDS',
    [meology_type, type]
  );

  const isMeologyRegular = useMemo(
    () => type === 'regular' || meology_type === 'REGULAR',
    [meology_type, type]
  );

  const isMeologyPrenatal = useMemo(
    () => type === 'prenatal' || meology_type === 'PRENATAL',
    [meology_type, type]
  );

  const hasRegularInAutoship = useMemo(
    () => (isUserLoggedin || isImpersonation) && hasRegularMeologyOrderEntry,
    [hasRegularMeologyOrderEntry, isImpersonation, isUserLoggedin]
  );

  const hasPrenatalInAutoship = useMemo(
    () => (isUserLoggedin || isImpersonation) && hasMeologyPrenatalOrderEntry,
    [hasMeologyPrenatalOrderEntry, isImpersonation, isUserLoggedin]
  );

  const hideCartBtn = useMemo(() => {
    if (mode === 'restore') {
      return true;
    } else if (isMeologyRegular) {
      return hasRegularInAutoship;
    } else if (isMeologyPrenatal) {
      return hasPrenatalInAutoship;
    }
  }, [
    mode,
    isMeologyRegular,
    isMeologyPrenatal,
    hasRegularInAutoship,
    hasPrenatalInAutoship,
  ]);

  const hideAutoCartBtn = useMemo(() => {
    if (mode === 'restore') {
      return false;
    } else if (isUserLoggedin || (isImpersonation && !isSponsor)) {
      return isEmpty(loyalty_autoship_info) && isEmpty(meology_autoship_info);
    } else {
      return true;
    }
  }, [
    mode,
    isUserLoggedin,
    loyalty_autoship_info,
    meology_autoship_info,
    isImpersonation,
    isSponsor,
  ]);

  const isOneBtn = hideCartBtn || hideAutoCartBtn;

  return {
    ...history,
    accountEmail: email,
    isOneBtn,
    kids_info,
    hideCartBtn,
    hideAutoCartBtn,
    newKidsInfo,
    isMeologyRegular,
    isMeologyPrenatal,
    isMeologyKids,
    meology_type,
    newKidsNumber,
    autoshipKidsId,
    isView,
    urlMeologyType: type?.toUpperCase(),
    prenatalHistory: history,
    hasMeologyPrenatalOrderEntry: hasPrenatalInAutoship,
    hasRegularMeologyOrderEntry:  hasRegularInAutoship,
    autoshipInfos,
    hasPurchasedPrenatalConceptionPack: (isUserLoggedin || isImpersonation) && hasPurchasedPrenatalConceptionPack,
    isPrenatalToRegular
  };
};

export const getImpersonation = () => {
  const { csm, asm } = getUrlVars();
  const meologyUplineID = Cookies.get('meology_uplineID');
  const sponsorId = Cookies.get('sponsorId') || '';
  const isBL = asm === 'true' || !isEmpty(meologyUplineID);
  const isCSR = csm === 'true';
  const isSponsor = !isEmpty(meologyUplineID) && !isEmpty(sponsorId);
  const isImpersonation = isBL || isCSR;

  return {
    isBL,
    isCSR,
    isImpersonation,
	  isSponsor,
    sponsorId,
  };
};

export const useProfileId = () => {
  const { isUserLoggedin } = useSelector(state => state.user);
  const { isImpersonation, isSponsor } = getImpersonation();
  const { id } = useParams();
  const {
    kids_health_profile_id,
    regular_health_profile_id,
    prenatal_health_profile_id,
    isView,
  } = useMeologyHistory();
  const localKidsProfileId = getLocalStorage('kidsHealthProfile')
    ?.health_profile_id;
  const localRegularProfileId = getLocalStorage('regularHealthProfile')
    ?.health_profile_id;
  const localPrenatalProfileId = getLocalStorage('prenatalHealthProfile')
    ?.health_profile_id;

  if (isSponsor) {
    return {
      kidsProfileId: localKidsProfileId,
      regularProfileId: localRegularProfileId,
      prenatalProfileId: localPrenatalProfileId,
    };
  } else if (isUserLoggedin) {
    return {
      kidsProfileId: kids_health_profile_id || localKidsProfileId,
      regularProfileId: regular_health_profile_id || localRegularProfileId,
      prenatalProfileId: prenatal_health_profile_id || localPrenatalProfileId,
    };
  } else if (isView || isImpersonation || id) {
    return {
      kidsProfileId: kids_health_profile_id,
      regularProfileId: regular_health_profile_id,
      prenatalProfileId: prenatal_health_profile_id,
    };
  } else {
    return {
      kidsProfileId: localKidsProfileId,
      regularProfileId: localRegularProfileId,
      prenatalProfileId: localPrenatalProfileId,
    };
  }
};

export const useHybrisUrlParams = (lang, country) =>
  useMemo(() => getHybrisUrlParams(lang, country), [lang, country]);

export const useHybrisHost = country =>
  useMemo(() => getHybrisHost(country), [country]);

export const useOrderUrl = () => {
  const { lang, country } = useSelector(state => state.user);
  const hybrisHost = useHybrisHost(country);
  const hybrisUrlParams = useHybrisUrlParams(lang, country);
  const pageUrl = '/my-account/manage-autoship';
  return hybrisHost + pageUrl + hybrisUrlParams;
};

export const useDebounce = (value, delay = 300) => {
  const [debouncedValue, setDebouncedValue] = useState(value);
  useEffect(() => {
    const handler = window.setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
};

export const useUpdateEffect = (effect, dependencies = []) => {
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      return effect();
    }
  }, dependencies); // eslint-disable-line react-hooks/exhaustive-deps
};

export const useKidsSachetPrice = () => {
  const { hybrisProductsInfo } = useSelector(
    state => state.recommendationsReducer
  );
  const { country } = useSelector(state => state.user);
  const kidsSachetSku = useKidsSachetSku(country);
  const kidsSachetInfo = useMemo(
    () => _.find(hybrisProductsInfo, (value, key) => key === kidsSachetSku),
    [kidsSachetSku, hybrisProductsInfo]
  );

  return useProductPrice(kidsSachetInfo);
}

export const useKidsPricePerDay = () => {
  const { priceTier } = useSelector(state => state.user);
  const map = {
    SRP: 1.76,
    MP: 1.47,
    DP: 1.24,
    SN: 1.08,
  };
  if (priceTier) {
    return map[priceTier].toFixed(2);
  } else {
    return map.MP.toFixed(2);
  }
};

export const useCopyToClipboard = (text, timeout) => {
  const copyToClipboard = str => {
    const el = document.createElement('textarea');
    el.value = str;
    el.setAttribute('readonly', '');
    el.style.position = 'absolute';
    el.style.left = '-9999px';
    document.body.appendChild(el);
    const selected =
      document.getSelection().rangeCount > 0
        ? document.getSelection().getRangeAt(0)
        : false;
    el.select();
    const success = document.execCommand('copy');
    document.body.removeChild(el);
    if (selected) {
      document.getSelection().removeAllRanges();
      document.getSelection().addRange(selected);
    }
    return success;
  };

  const [copied, setCopied] = useState(false);
  const timeoutRef = useRef();

  const copy = useCallback(() => {
    if (!copied) {
      setCopied(copyToClipboard(text));
      clearTimeout(timeoutRef.current);
      timeoutRef.current = window.setTimeout(() => {
        setCopied(false);
      }, timeout);
    }
  }, [copied, text, timeout]);

  useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current);
      setCopied(false);
    };
  }, [timeout]);

  return [copied, copy];
};

export const useCurrentLink = () => {
  const { lang, country } = useSelector(state => state.user);
  const { pwsNames, account_type, ambassador } = useMeologyHistory();
  const { origin, pathname, search } = window.location;
  const urlParams = new URLSearchParams(search.slice(1));
  const pwsName = pwsNames?.[0];
  const needParams =
    (account_type === 'MEMBER' && ambassador) ||
    ['DISTRIBUTOR', 'BUSINESS_LEADER'].includes(account_type);
  
  if (!urlParams.has('lang')) {
    urlParams.set('lang', `${lang}_${country}`);
  }

  if (!urlParams.has('country')) {
    urlParams.set('country', country);
  }

  if (needParams && pwsName) {
    if (!urlParams.has('site')) {
      urlParams.set('site', pwsName);
    }
  }

  return `${origin}${pathname}?${urlParams}`
}

export const useIsMarketing = (page) => {
  const { isExact: isRegular } = useRouteMatch('/meology') || {};
  const { isExact: isKids } = useRouteMatch('/meologykids') || {};
  const { isExact: isPrenatal } = useRouteMatch('/meologyprenatal') || {};

  const isRegularMarketing = useMemo(() => isRegular || page === "marketing", [isRegular, page]);
  const isKidsMarketing = useMemo(() => isKids || page === "kidsMarketing", [isKids, page]);
  const isPrenatalMarketing = useMemo(() => isPrenatal || page === "prenatalMarketing", [isPrenatal, page]);

  const isMarketing = useMemo(() => isRegularMarketing || isKidsMarketing || isPrenatalMarketing, [
    isRegularMarketing,
    isKidsMarketing,
    isPrenatalMarketing
  ]);
  if(isRegularMarketing) {
    setLocalStorage('meologyType',REGULAR_TYPE)
  }
  if(isKidsMarketing) {
    setLocalStorage('meologyType',KIDS_TYPE)
  }
  if(isPrenatalMarketing) {
    setLocalStorage('meologyType',PRENATAL_TYPE)
  }

  return {
    isMarketing,
    isRegularMarketing,
    isKidsMarketing,
    isPrenatalMarketing,
  };
};

export const usePrenatalStatus = () => {
  const { reproductiveStatus, prenatalStep } = useSelector(
    state => state.recommendationsReducer
  );

  const {isPrenatalToRegular} = useMeologyHistory()

  const isPrenatalPreview = useMemo(() => isPrenatalToRegular || (reproductiveStatus !== prenatalStep), [isPrenatalToRegular, prenatalStep, reproductiveStatus]);

  const isPreviewPostnatal = useMemo(
    () => prenatalStep === 'POSTPARTUM' && !isPrenatalToRegular && (reproductiveStatus !== prenatalStep),
    [isPrenatalToRegular, prenatalStep, reproductiveStatus]
  );

  const phaseStatus = useMemo(
    () => getPrenatalPhaseStatus(prenatalStep, reproductiveStatus),
    [prenatalStep, reproductiveStatus]
  );
  const isPreviewPregnancy  = useMemo(
    () =>(prenatalStep === 'PREGNANT') && phaseStatus.next,
    [phaseStatus.next, prenatalStep]
   )

  return {
    isPrenatalPreview,
    isPreviewPostnatal,
    phaseStatus, // phaseStatus data structure: {past： boolean, current: boolean, next: boolean}
    reproductiveStatus,
    prenatalStep,
    isPreviewPregnancy
  };
};

export const useHideMeology = () => {
  const { isView } = useMeologyHistory();
	const hideKids = Cookies.get('kids_visible') === 'false';
  const hidePrenatal = Cookies.get('prenatal_visible') === 'false';
  const isOne = hideKids && hidePrenatal;
  const isShowToggle = !isOne && !isView;
  return {
    isOne,
    hideKids,
    hidePrenatal,
    isShowToggle
  }
}

export const useRSWStatus = () => {
  const {
    joinDate,
    account_type: accountType,
    hasWK,
    isMeologyRegular,
    isMeologyPrenatal,
  } = useMeologyHistory();

  const user = useSelector(state => state.user);
  const { country } = user || {};

  const {isJoinFlow, isPdpFlow} = useMemo(() => isRSW(), [])
  
  const isValidTime = useMemo(
    () =>
      isMeologyPrenatal //There is not logic of Join Date for Prenatal + RSW. 
        ? true
        : isRSWValidTime({
            joinDate,
            isPdpFlow,
            isJoinFlow,
            accountType,
          }),
    [accountType, isJoinFlow, isMeologyPrenatal, isPdpFlow, joinDate]
  );

  const isPi = _.isBoolean(hasWK) ? hasWK : hasWK === 'true';
 
  const isValid = useMemo(
    () => !isPi && country !== 'CA' && isValidTime,
    [country, isPi, isValidTime]
  );
  const showPromo = !isJoinFlow && isValid
  const showPdpPromo = !isPdpFlow && isValid
  const showStep = isJoinFlow && isValid
  
  // Comment the following lines for hide Prenatal RSW
  // const showPdpStep = isPdpFlow && isValid
  // const isPrenatalJionFlow = isMeologyPrenatal && (showStep || showPdpStep);
  const isPrenatalJionFlow = false;

  const isRegularJionFlow = isMeologyRegular && showStep;
  const isBelowMember = !accountType || ['ANONYMOUS', 'GUEST'].includes(accountType);

  return {
    isValid,
    isPdpFlow,
    isJoinFlow,
    isRSWJionFlow: isRegularJionFlow || isPrenatalJionFlow,
    isRegularJionFlow,
    isPrenatalJionFlow,
    isIndependentFlow: (isMeologyRegular || isMeologyPrenatal) && showPromo,
    isRegularIndependentFlow: isMeologyRegular && showPromo && isBelowMember,
    isPrenatalIndependentFlow: isMeologyPrenatal && showPromo && showPdpPromo && isBelowMember,
  };
};
